import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import firebase from './Firestore'
import SignOut from './Auth/SignOut'
import InfluencerNavbarStyle from './css/InfluencerNavbarStyle.css'
import mark from './img/markWithTextSmall.png'

function DefaultHeader(props){
    let user = firebase.auth().currentUser;
    let signin = 
    (
        user == null && 
        !window.location.href.includes('/signin') && 
        !window.location.href.includes('/campaigns')
    ) ?
    <Nav>
        <Button onClick={() => window.location.pathname = '/signin'}>Sign In</Button>
    </Nav>
    : null;

    let signout = (user !== null) ? 
    <Nav>
        <Button onClick={() => {SignOut()}}>Sign Out</Button>
    </Nav> :
    null;

    let light = props.lightMode;

    return(
        <div>
            <div className={light ?
            "navbar-container-light" : "navbar-container"}>
            
                <div 
                className="navbar-mark-container"
                onClick={() => window.location = '/'}>
                    <img
                    className="navbar-mark"
                    src={mark}/>
                </div>

                <div 
                className="navbar-button">
                    {signin}
                    {signout}
                </div>

            </div>
        </div>
    )
}

export default DefaultHeader