import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/storage'

//PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyBPKmRSti_aazbgUIVNIMx0fX9cyomB8TI",
  authDomain: "tiktokstaging.firebaseapp.com",
  databaseURL: "https://tiktokstaging.firebaseio.com",
  projectId: "tiktokstaging",
  storageBucket: "tiktokstaging.appspot.com",
  messagingSenderId: "196006728691",
  appId: "1:196006728691:web:2e203f420f2aa150d6b886",
  measurementId: "G-6B9P0ET3QR"
};

//STAGING
// var firebaseConfig = {
//   apiKey: "AIzaSyB9FzMvLZLH3J7xZIIIvZFSs7pnOoz5skc",
//   authDomain: "btsstaging-d6b99.firebaseapp.com",
//   databaseURL: "https://btsstaging-d6b99.firebaseio.com",
//   projectId: "btsstaging-d6b99",
//   storageBucket: "btsstaging-d6b99.appspot.com",
//   messagingSenderId: "480594501374",
//   appId: "1:480594501374:web:d5e91f01b67ceb53944faf",
//   measurementId: "G-048VPTNH4T"
// };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
export default firebase;