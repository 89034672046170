import React from 'react'
import firebase from '../Firestore'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Redirect, Link } from 'react-router-dom'
import AuthDisconnected from '../Auth/AuthDisconnected'
import SignInStyle from './SignInStyle.css'
import QueryVariables from '../Util/QueryVariables'

const query = QueryVariables();
class SignIn extends React.Component{
    
    constructor(props)
    {
        super(props);
        this.state = {
            email: '',
            password: '',
            loggedIn: firebase.auth().currentUser,
            checked: false,
            rejected: false,
            firebaseResponded: false,
            userDoc: null
        };
        this.passwordInput = React.createRef();
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            this.setState({firebaseResponded: true});
            if (user) {

                if(query.inv !== undefined)
                    window.location = `/home?inv=${query.inv}`;

                if(query.sponsorship !== undefined)
                    window.location = '/sponsorship/' + query.sponsorship + 
                    (query.src === undefined ? '':'?src=' + query.src);

                firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get()
                .then(doc => {
                    this.setState({
                        loggedIn: true,
                        checked: true,
                        userDoc: doc.data()
                    })
                })
                
            } else {
                this.setState({
                    loggedIn: false,
                    checked: true
                });
            }
        });
    }

    handleChange = e =>
    {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e =>
    {
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(this.state.email,this.state.password)
        .then(result => {
            this.setState({
                rejected: false
            })
        }).catch(err =>{
            this.setState({
                rejected: true
            },() => {
                this.passwordInput.current.focus();
            })
        });
    }

    render()
    {
        if(!this.state.firebaseResponded)
            return(<AuthDisconnected/>);

        

        let forgotPasswordStyle = this.props.landingPage ? {marginLeft: '20px', color: 'white'} : {marginLeft: '20px'};

        if(this.state.loggedIn)
        {
            return(<Redirect to={this.state.userDoc.influencer? 'home':'sponsorHome'}/>)
        }

        let errorMessage = this.state.rejected ? <p style={{color:'red'}}>Email or password is invalid</p> : null;
        let labelColor = this.props.landingPage ? 'white':'black';
        return(
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formGroupEmail">
                        <Form.Label style={{color:labelColor}}>Email</Form.Label>
                        <Form.Control 
                        id="emailinput"
                        name="email" 
                        type="email" 
                        value={this.state.email} 
                        onChange={this.handleChange}/>
                    </Form.Group>

                    <Form.Group controlId="formGroupPassword">
                        <Form.Label style={{color:labelColor}}>Password</Form.Label>
                        <Form.Control 
                        ref={this.passwordInput} 
                        id="passwordinput"
                        name="password" 
                        type="password" 
                        value={this.state.password} 
                        onChange={this.handleChange}/>
                    </Form.Group>
                    {errorMessage}
                    <Button type="submit" onClick={this.handleSubmit}>Sign In</Button> 
                    <Link style={forgotPasswordStyle} to={'/resetpassword'}>Forgot your password?</Link>
                </Form>

                <div className="sign-up-links-outer">
                    Don't have an account?
                    <div className="sign-up-links">
                        <a href="/signup">Sign up as a creator</a>
                        <div className="sign-up-links-separator"/>
                        <a href="/sponsors/signup">Sign up as a sponsor</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignIn