import React from 'react'
import { Spinner } from 'react-bootstrap'

class LoadingAppScreen extends React.Component
{
    render()
    {
        return(
            <div 
            style={{
                padding:'20px', 
                height:'100vh', 
                display:'flex', 
                flexDirection:'column', 
                alignItems:'center', 
                justifyContent:'center', 
                textAlign:'center'
            }}>
                <Spinner variant="primary" animation="border"/>
            </div>
        );
    }
}

export default LoadingAppScreen