import { useEffect } from 'react'
import firebase from '../Firestore'
import QueryVariables from '../Util/QueryVariables'

export default function NewCampaignRedirect() {
    const query = QueryVariables();
    useEffect(() => {
        firebase.auth().onAuthStateChanged(u => {
            if(u)
                getUser(u.uid);
            else
                window.location = '/sponsors/signup' + window.location.search;
        });
    },[])
    const getUser =async (uid)=> {
        let snap = await firebase.firestore().collection('users').doc(uid).get();
        if(snap.data().influencer)
            window.location='/sponsor'+window.location.search;
        else
            window.location = 
            (query.type === 'blitz' ? '/newCampaign':'/newOffer') + 
            window.location.search;
    }
    return null;
}

//Redirect possibilities

    //Logged out, blitz targeting user ✅
        //redirect to sponsor sign up with user and type params
        //sign up
        //redirect after sign up to /newCampaign?user=uid
    //Logged out, blitz targeting category ✅
        //redirect to sponsor sign up with category and type params
        //sign up
        //redirect after sign up to /newCampaign?category=category_id
    //Logged out, direct offer ✅
        //redirect to sponsor sign up with user and type params
        //sign up
        //redirect after sign up to /newOffer?user=uid

    //Logged in sponsor, blitz targeting user ✅
        //redirect to /newCampaign?user=uid
    //Logged in sponsor, blitz targeting category ✅
        //redirect to /newCampaign?category=category_id
    //Logged in sponsor, direct offer ✅
        //redirect to /newOffer?user=uid&type=sound
    
    //Logged in influencer, blitz targeting user ✅
        //redirect to /sponsor?user=uid&type=blitz
        //sign out and redirect to /sponsors/signup?user=uid&type=blitz
        //sign up
        //redirect after sign up to /newCampaign?user=uid
    //Logged in influencer, blitz targeting category ✅
        //redirect to /sponsor?category=category_id&type=blitz
        //redirect to sponsor sign up with category and type params
        //sign up
        //redirect after sign up to /newCampaign?category=category_id
    //Logged in influencer, direct offer ✅
        //redirect to /sponsor?user=uid&type=sound
        //redirect to sponsor sign up with user and type params
        //sign up
        //redirect after sign up to /newOffer?user=uid