import React from 'react'
import DefaultHeader from '../DefaultHeader'
import SignIn from './SignIn'

function SignInPage()
{
    return(
        <div>
            <DefaultHeader/>
            <div style={{maxWidth:'500px', paddingRight:'15px', paddingLeft:'15px', marginTop:'70px'}}>
                <h1>Sign In</h1>
                <br/>
                <SignIn landingPage={false}/>
            </div>
        </div>
    );
}

export default SignInPage