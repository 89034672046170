import React, { Suspense, lazy } from 'react'
import './css/App.css'
import SignInPage from './Auth/SignInPage'
import 'bootstrap/dist/css/bootstrap.min.css'
import LoadingAppScreen from './Util/LoadingAppScreen'
import MissingPage from './Util/MissingPage'
import NewCampaignRedirect from './NewCampaignRedirect/NewCampaignRedirect'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom"

import CacheBuster from './CacheBuster'

import {Helmet} from 'react-helmet'

const LandingPage = lazy(() => import('./LandingPage'));
const Dashboard = lazy(() => import('./Dashboard'));
const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
const StripeConnectionCompletion = lazy(() => 
  import('./InfluencerView/StripeConnectionCompletion')
);

const RedirectFromLegacyInvite = lazy(() => import('./Home/RedirectFromLegacyInvite'));
const PerformanceAssessmentPage = lazy(() => import('./AdminTools/PerformanceAssessmentPage'));
const ErrorBoundary = lazy(() => import('./Util/ErrorBoundary'));
const TermsOfServicePage = lazy(() => import('./Public/TermsOfServicePage'));
const LinkPage = lazy(() => import('./LinkPage/LinkPage'));
const LinkPageEditor = lazy(() => import('./LinkPageEditor/LinkPageEditor'));
const InfluencerHub = lazy(() => import('./Home/InfluencerHub'));
const InfluencerApplication = lazy(() => import('./Home/InfluencerApplication'));
const SponsorshipPage = lazy(() => import('./SponsorshipPage/SponsorshipPage'));
const LegacySponsorshipPage = lazy(() => import('./CommunityForum/CampaignPageFunctional'));
const RedirectToNewSponsorship = lazy(() => import('./CommunityForum/RedirectToNewSponsorship'));
const SponsorshipHistory = lazy(() => import('./Home/SponsorshipHistory'));
const EmailSubscribersComingSoon = lazy(() => import('./Home/EmailSubscribersComingSoon'));
const CloudFunctionTester = lazy(() => import('./Util/CloudFunctionTester'));
const ChangeUsername = lazy(() => import('./Home/ChangeUsername'));
const ChangeTikTok = lazy(() => import('./Home/ChangeTikTok'));
const SignUp = lazy(() => import('./Auth/UserSignUpPage'));
const SponsorSignUp = lazy(() => import('./Auth/UniversalSignUp'));
const Campaigns = lazy(() => import('./BrandDashboard/Campaigns'));
const NewCampaign = lazy(() => import('./BrandDashboard/NewCampaign'));
const NewOffer = lazy(() => import('./BrandDashboard/NewOffer'));
const CampaignPage = lazy(() => import('./BrandDashboard/CampaignPage'));
const EditCampaign = lazy(() => import('./BrandDashboard/EditCampaign'));
const SponsorPage = lazy(() => import('./SponsorPage/SponsorPage'));
const DirectOfferMessages = lazy(() => import('./DirectOfferMessages/DirectOfferMessages'));
const DirectOfferPage = lazy(() => import('./DirectOfferPage/DirectOfferPage'));
const EditSponsorshipSubPage = lazy(() => import('./EditSponsorshipPage/EditSponsorshipSubPage'));
const Directory = lazy(() => import('./Directory/Directory'));
const DeclineOffer = lazy(() => import('./DeclineOffer/DeclineOffer'));
const SponsorExplainer = lazy(() => import('./SponsorshipExplainer/SponsorshipExplainer'));
const SponsorHome = lazy(() => import('./SponsorHome/SponsorHome'));
const EmailVerification = lazy(() => import('./EmailVerificationPage/EmailVerification'));
const PhoneVerification = lazy(() => import('./PhoneVerificationPage/PhoneVerification'));
const ListsSunsetMessage = lazy(() => import('./Lists/ListsSunsetMessage'));
const TikTokOAuth = lazy(() => import('./Auth/TikTokOAuth'));
const CSVExport = lazy(() => import('./BrandDashboard/CSVExport'));
const Affiliate = lazy(() => import('./BrandDashboard/Affiliate/Affiliate'));
const Reauth = lazy(() => import('./BrandDashboard/Affiliate/Reauth'));
const InfluencerReauth = lazy(() => import('./InfluencerView/InfluencerReauth'));
const InvoicePDF = lazy(() => import('./AdminTools/InvoicePDF'));
const CrowdFRA = lazy(() => import('./CrowdFRA/CrowdFRA'));
const SubmissionVerdict = lazy(() => import('./AdminTools/SubmissionVerdict'));
const SponsorSettings = lazy(() => import('./SponsorSettings/SponsorSettings'));
const Inbox = lazy(() => import('./Inbox/Inbox'));
const Agency = lazy(() => import('./Agency/Agency'));
const Agencies = lazy(() => import('./Agencies/Agencies'));
const RequestAgencyCampaign = lazy(() => import('./RequestAgencyCampaign/RequestAgencyCampaign'));
const CampaignSelect = lazy(() => import('./CampaignSelect'));
const TotalPaidCaching = lazy(() => import('./AdminTools/TotalPaidCaching'));
const Keys = lazy(() => import('./Keys/Keys'));
const FirstRoundApproval = lazy(() => import('./AdminTools/FirstRoundApproval'));
const ShutdownNotice = lazy(() => import('./ShutdownNotice'))

export default function App() {

  return (
    <Suspense fallback={<LoadingAppScreen/>}>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return <LoadingAppScreen/>;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return(
            <ErrorBoundary>
              <Helmet>
                <meta charSet="utf-8" />
                <title>BigTinySound</title>
                <meta 
                name="description" 
                content="A unique marketplace for TikTok creators."/>
            </Helmet>
              <Router>
                  <Switch>
                  <Route exact path="/" component={LandingPage}/>
                  <Route path="/signin" component={SignInPage}/>
                  <Route path="/signup" component={SignUp}/>
                  <Route path="/sponsors/signup" component={SponsorSignUp}/>
                  <Route path="/home" component={InfluencerHub}/>
                  <Route path="/inbox" component={Inbox}/>
                  <Route path="/inviteLink" component={InfluencerHub}/>
                  <Route path="/support" component={InfluencerHub}/>
                  <Route path="/settings" component={InfluencerHub}/>
                  <Route path="/sponsorshipSettings" component={InfluencerHub}/>
                  <Route path="/editSponsorships" component={EditSponsorshipSubPage}/>
                  <Route path="/history" component={SponsorshipHistory}/>
                  <Route path="/sponsorship" component={SponsorshipPage}/>
                  <Route path="/sponsorshipLegacy" component={LegacySponsorshipPage}/>
                  <Route path="/apply" component={InfluencerApplication}/>
                  <Route path="/resetpassword" component={ResetPassword}/>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/confirmcontractor" component={StripeConnectionCompletion}/>
                  <Route path="/community/sponsorship" component={RedirectToNewSponsorship}/>
                  <Route path="/invite" component={RedirectFromLegacyInvite}/>
                  <Route path="/admin" component={PerformanceAssessmentPage}/>
                  <Route path='/termsofuse' component={TermsOfServicePage}/>
                  <Route path="/@:name" component={LinkPageRouter}/>
                  <Route path="/bio" component={LinkPageEditor}/>
                  <Route path="/emailSubs" component={EmailSubscribersComingSoon}/>
                  <Route path="/test" component={CloudFunctionTester}/>
                  <Route path="/changeUsername" component={ChangeUsername}/>
                  <Route path="/changeTikTok" component={ChangeTikTok}/>
                  <Route path="/campaigns" component={ShutdownNotice}/>
                  <Route path="/newCampaign" component={ShutdownNotice}/>
                  <Route path="/newoffer" component={NewOffer}/>
                  <Route path="/campaign" component={CampaignPage}/>
                  <Route path="/edit" component={EditCampaign}/>
                  <Route path="/dm" component={DirectOfferMessages}/>
                  <Route path="/offer" component={DirectOfferPage}/>
                  <Route path="/creators" component={Directory}/>
                  <Route path="/decline" component={DeclineOffer}/>
                  <Route path="/sponsor" component={SponsorExplainer}/>
                  <Route path="/sponsorHome" component={SponsorHome}/>
                  <Route path="/nc" component={NewCampaignRedirect}/>
                  <Route path="/verifyEmail" component={EmailVerification}/>
                  <Route path="/verifyPhone" component={PhoneVerification}/>
                  <Route path="/lists" component={ListsSunsetMessage}/>
                  <Route path="/tiktokoauth" component={TikTokOAuth}/>
                  <Route path="/export" component={CSVExport}/>
                  <Route path="/affiliate" component={Affiliate}/>
                  <Route path="/reauth" component={Reauth}/>
                  <Route path="/influencerReauth" component={InfluencerReauth}/>
                  <Route path="/invoicepdf" component={InvoicePDF}/>
                  <Route path="/review" component={CrowdFRA}/>
                  <Route path="/verdict" component={SubmissionVerdict}/>
                  <Route path="/sponsorSettings" component={SponsorSettings}/>
                  <Route path="/bundle" component={Agency}/>
                  <Route path="/bundles" component={Agencies}/>
                  <Route path="/requestcampaign" component={RequestAgencyCampaign}/>
                  <Route path="/campaignselect" component={CampaignSelect}/>
                  <Route path="/totalpaidcaching" component={TotalPaidCaching}/>
                  <Route path="/api" component={Keys}/>
                  <Route path="/qa" component={FirstRoundApproval}/>
                  <Route component={() => MissingPage('/')}/>
                  </Switch>
              </Router>
            </ErrorBoundary>
          )
        }}
      </CacheBuster>
    </Suspense>
  );
}

function LinkPageRouter() {
  let match = useRouteMatch();
  return(
    <Switch>
      <Route exact path={`${match.url}/`} component={LinkPage}/>
      <Route path={`${match.url}/sponsorships`} component={SponsorPage}/>
    </Switch>
  );
}