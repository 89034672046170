import React, {useState, useEffect} from 'react'

function AuthDisconnected(props) {
    const [time, setTime] = useState(0);
    const [showDisplay, setShowDisplay] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if(time >= 3) {
                setShowDisplay(true);
                clearInterval(interval);
            }else
                setTime(time + 0.5);
          }, 500);
          return () => clearInterval(interval);
    });

    
    return(
        <div>
            {showDisplay ?
                <div style={{padding:'20px'}}>
                    <h3>Oh no! <br/><br/>
                    It looks like you have too many BigTinySound windows open right 
                    now. <br/><br/>
                    To fix this issue:</h3>
                    <br/>
                    <h5>1. Close the other BigTinySound windows/tabs you have open.</h5>
                    <br/>
                    <h5>2. Refresh this page.</h5>
                </div>:null}
        </div>
    )
}
export default AuthDisconnected