import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function MissingPage(path) {
    return(
        <div style={{padding:'20px', 
                    height:'100vh', 
                    display:'flex', 
                    flexDirection:'column', 
                    alignItems:'center', 
                    justifyContent:'center', 
                    textAlign:'center'}}>

                <h3 style={{marginBottom:'30px'}}>
                    404 - The page you're looking for isn't here
                </h3>
                
                <Link to={path}>
                    <Button block variant="outline-primary">
                        Home
                    </Button>
                </Link>
                
            </div>
    )
}
export default MissingPage